<template>
  <b10-base>
    <b10-autocomplete
      v-if="isInsert"
      v-model="form.idsubsis"
      :items="subsis"
      item-value="subsis.idsubsis"
      item-text="subsis.descripcion"
      label="Subsistema"
      clearable
      :rules="formRules.idsubsis"
      multiple
      chips
      deletable-chips
    />
    <b10-autocomplete
      v-model="form.idmaccion"
      :items="formattedMacciones"
      item-value="maccion.idmaccion"
      item-text="title"
      label="Tipo de acción"
      clearable
      :rules="formRules.idmaccion"
      multiple
      chips
      deletable-chips
      return-object
      @change="maccionSelected"
    />
    <v-checkbox
      v-model="form.facturable"
      label="Es facturable"
    />
    <v-checkbox
      v-model="form.realizada"
      label="Marcar acción realizada"
      hint="Algunas acciones requieren introducir más datos."
      :persistent-hint="noRealizable"
      :disabled="noRealizable"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './AccionEjecMasivaFormData'
import _ from '@/utils/lodash'
import { MACCION } from '@/utils/consts'

export default {
  mixins: [formMixin],
  props: {
    idparteTrabajo: {
      type: [Number, String],
      required: true,
    },
  },
  data () {
    return {
      form: {
        idsubsis: null,
        idmaccion: null,
        facturable: true,
        realizada: false,
      },
      formRules: {
        idsubsis: [
          v => !!v || 'Campo requerido'
        ],
        idmaccion: [
          v => !!v || 'Campo requerido'
        ],
      },
      macciones: [],
      subsis: [],
      noRealizable: false,
    }
  },
  computed: {
    formattedMacciones () {
      const items = []
      for (let item of this.macciones) {
        item.title = `${item.maccion.descripcion} (${item.maccion.descripcion_corta})`
        items.push(item)
      }
      return items
    },
  },
  async created () {
    this.macciones = await Data.selectMaccion(this)
    const subsisAfectados = await Data.selectSubsis(this, this.idparteTrabajo)
    this.subsis = _.filter(subsisAfectados, item => item.cantidadMaterialAfectado > 0)
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    maccionSelected (value) {
      // la primera acción que selecciona determina el facturable incial luego
      // por defecto -> es facturable
      const clasificaciones = _.map(value, 'maccion.clasificacion')
      const algunaNoRealizable = _.intersection(clasificaciones, MACCION.NoRealizableMasivamente())
      this.noRealizable = algunaNoRealizable.length > 0
      if (value.length === 1) {
        this.$set(this.form, 'facturable', value[0].maccion.facturable)
      } else {
        this.$set(this.form, 'facturable', true)
      }
    },
  }
}
</script>
