<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <accion-ejec-masiva-form
          v-if="dbReady"
          :id="null"
          :idparte-trabajo="routeParams.idparte_trabajo"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import AccionEjecMasivaForm from './components/AccionEjecMasivaForm'
import Data from './AccionEjecMasivaAddData'
import { get } from 'vuex-pathify'

export default {
  components: {
    AccionEjecMasivaForm
  },
  mixins: [formPageMixin],
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
  },
  async created () {
    // routeQuery.idchecklist_ot: checklist del parte
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Añadir acción al material'
    },
    async submitForm () {
      await Data.insert(
        this, this.formData, this.routeParams.idparte_trabajo, this.routeQuery.idchecklist_ot, this.usuarioIdtecnico
      )
      this.$appRouter.go(-1)
    },
  },
}
</script>
