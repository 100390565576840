export default {
  selectMaccion (Vue) {
    const tables = Vue.$offline.db.tables
    const maccion = Vue.$offline.maccion
    return Vue.$offline.db
      .select()
      .from(tables.maccion)
      .innerJoin(maccion.dummyTable, maccion.pk.eq(maccion.dummyPk))
      .where(
        Vue.$offline.db.op.and(
          tables.maccion.estado.gt(0),
        )
      )
      .orderBy(tables.maccion.orden)
      .exec()
  },
  async _addSubqueriesCantidadMaterialAfectadoPorSubsis (Vue, rows) {
    for (let row of rows) {
      const tables = Vue.$offline.db.tables
      const cant = await Vue.$offline.db
        .select(
          Vue.$offline.db.fn.count(
            tables.parte_trabajo_matsist.idparte_trabajo_matsist
          ).as('cant_material_afectado')
        )
        .from(tables.parte_trabajo_matsist)
        .innerJoin(
          tables.orden_trabajo_matsist,
          tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
        )
        .innerJoin(
          tables.material_sistema,
          tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
        )
        .innerJoin(
          tables.subsis,
          tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
        )
        .where(
          Vue.$offline.db.op.and(
            tables.subsis.idsubsis.eq(row.subsis.idsubsis),
            tables.orden_trabajo_matsist.idorden_trabajo.eq(row.orden_trabajo_subsis.idorden_trabajo)
          )
        ).exec()
      row.cantidadMaterialAfectado = cant[0].cant_material_afectado
    }
    return rows
  },
  async selectSubsis (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    const rows = await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo_subsis,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo_subsis.idorden_trabajo)
      )
      .innerJoin(
        tables.subsis,
        tables.orden_trabajo_subsis.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.orden_trabajo_subsis.estado.gt(0),
          tables.subsis.estado.gt(0)
        )
      )
      .orderBy(tables.subsis.orden)
      .exec()
    return await this._addSubqueriesCantidadMaterialAfectadoPorSubsis(Vue, rows)
  },
}
